import React from 'react';
import styled from 'styled-components';
import ProfileSideNav from '../../components/ProfileSideNav';
import queryParams from '../../utils/queryParams';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {message, Result} from 'antd';
import Cart from '../../components/Cart';
import {
  TotalItemAmountForm,
  OrderPaymentMethodForm,
  OrderInfoForm,
  OrderDeliveryForm,
  OrderInvoiceForm,
} from '../../components/CartCofigForms';
import {
  BREAK_POINTS,
  LOGISTICE_TYPE,
  LOGISTICE_TYPE_CATEGORY,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS,
  PAYMENT_STATUS_DISPLAY,
} from '../../domain/Constants';
import SvgIconPaySuccess from '../../images/svg-icon-pay-success.svg';
import SvgIconPayFailure from '../../images/svg-icon-pay-failure.svg';
import {format} from '../../utils/date';
import Button from '../../components/Button';
import {navigate} from 'gatsby';
import URL_PATH from '../../UrlPath';
import Gtag from '../../utils/gtag';
import qs from 'query-string';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function OrderPage(props) {
  const {
    location: {search},
  } = props;
  const app = React.useContext(AppContext.Context);
  const [order, setOrder] = React.useState(null);
  const [preOrder, setPreOrder] = React.useState(null);
  const [fetchingOrder, setFetchingOrder] = React.useState(false);
  const [reFetching, setReFetching] = React.useState(false);
  let _dataInUrl = queryParams(search);
  let _orderNumber = _dataInUrl.order_number;
  let _orderId = _dataInUrl.id;
  let _isCreated = _dataInUrl.is_created;

  React.useEffect(() => {
    const _fetchOrderById = async (id) => {
      AppActions.setLoading(true);
      try {
        let order = await app.actions.getOrderByOrderId(id);
        setOrder(order);
      } catch (err) {
        //
        message.error(`無法取得訂單資訊：${id}`);
      }
      AppActions.setLoading(false);
    };

    const _fetchOrderByNumber = async (no) => {
      AppActions.setLoading(true);
      try {
        let order = await app.actions.getOrderByOrderNumber(no);
        setOrder(order);
        let _udpatedCart = await app.actions.refreshCart();
      } catch (err) {
        //
        if (err.status === 404) {
          setReFetching(true);
          const hide = message.loading('同步訂單資訊中', 0);
          setTimeout(hide, 15000);
        } else {
          message.error(`無法取得訂單資訊：${no}`);
        }
      }
      AppActions.setLoading(false);
    };

    if (app.profile) {
      if (_orderId) {
        _fetchOrderById(_orderId);
      } else if (_orderNumber) {
        _fetchOrderByNumber(_orderNumber);
      }
    }
  }, [_orderNumber, _orderId, app.profile]);

  React.useEffect(() => {
    if (!order) {
      return;
    }
    if (_isCreated === 'true') {
      try {
        Gtag('event', 'purchase', {
          transaction_id: order.order_number,
          currency: 'TWD',
          value: order.total,
          tax: order.tax,
          items: order.items.map((i) => ({
            item_id: i.product._id?.$oid,
            item_name: i.product.name,
            price: i.spec.price,
            quantity: i.config.qty,
          })),
        });
      } catch (e) {
        console.log(e);
      }
      const params = queryParams(window.location.search);
      delete params.is_created;
      navigate(`${window.location.pathname}?${qs.stringify(params)}`, {
        replace: true,
      });
    }
  }, [_isCreated, order]);

  const _onRefetchOrderButtonClick = React.useCallback(async () => {
    if (preOrder) {
      setFetchingOrder(true);
      try {
        let order = await app.actions.getOrderByOrderNumber(
          preOrder.order_number,
        );
        setOrder(order);
        setPreOrder(null);
        let _udpatedCart = await app.actions.refreshCart();
      } catch (err) {
        //
        message.warning('訂單款項尚未處理完畢');
      }
      setFetchingOrder(false);
    }
  }, [preOrder]);

  const reFetchOrder = React.useCallback(async (no) => {
    const retryLimit = 3;
    let _order = null;
    for (let i = 0; i < retryLimit; i++) {
      try {
        await delay(1000);
        _order = await app.actions.getOrderByOrderNumber(no);
        setOrder(_order);
        break;
      } catch (err) {
        // ignore
      }
    }

    if (!_order) {
      // if still fail after retry, try to fetch pre-order data
      try {
        let _preOrder = await app.actions.getPreOrderByOrderNumber(no);
        setPreOrder(_preOrder);
      } catch (err) {
        //
        message.error('無法取得訂單預覽資訊！');
      }
    } else {
      try {
        // order create success, update cart
        let _udpatedCart = await app.actions.refreshCart();
      } catch (err) {
        //
      }
    }
    setReFetching(false);
  }, []);

  React.useEffect(() => {
    if (reFetching) {
      reFetchOrder(_orderNumber);
    }
  }, [reFetching, _orderNumber]);

  const showPayFailHint = React.useMemo(() => {
    if (order && order.payment_status !== PAYMENT_STATUS.success) {
      return true;
    }
    return false;
  }, [order]);

  const showLogisticSearchLink = React.useMemo(() => {
    if (
      order &&
      order.logistic_type !== LOGISTICE_TYPE.selfPick &&
      order.logistic_order_id
    ) {
      return true;
    }
    return false;
  }, [order]);

  if (reFetching) {
    return (
      <Wrapper>
        <Result
          title="正在取得訂單資訊"
          subTitle={'同步中，請稍候...'}
          status="info"
        />
      </Wrapper>
    );
  }

  if (preOrder) {
    return (
      <Wrapper>
        <div className="container">
          <div
            className="block payment-fail-hint"
            style={{
              border: '1px solid var(--color-glod)',
              backgroundColor: 'var(--color-glod-2)',
            }}>
            <p>{`訂單款項正在處理中`}</p>
            <Button
              loading={fetchingOrder}
              type={'secondary'}
              size={'small'}
              onClick={() => _onRefetchOrderButtonClick()}
              style={{marginTop: 10}}>
              重新整理
            </Button>
          </div>

          <div
            className="block order-status"
            style={{justifyContent: 'space-between'}}>
            <div className="col" style={{marginRight: 10}}>
              <h3>訂單狀態：處理中</h3>
            </div>
          </div>
          <div className="block payment-result">
            <div className="col title">
              <h3>
                單據編號<span className="order-id">{'編號產生中'}</span>
              </h3>
              <p>
                訂單成立時間：{format(preOrder.created, 'YYYY-MM-DD HH:mm')}
              </p>
            </div>
            <div className="item" style={{flex: 2}}>
              <img src={SvgIconPaySuccess} style={{width: 70, height: 70}} />
              <div className="col">
                <h3>付款狀態：款項處理中</h3>
                <p></p>
              </div>
            </div>
          </div>
          <Cart cart={preOrder} />
          <div className="row">
            <div style={{flex: 2}}>
              <div className="form">
                <OrderPaymentMethodForm order={preOrder} />
              </div>
              <div className="form">
                <OrderInfoForm order={preOrder} />
              </div>
              <div className="form">
                <OrderDeliveryForm order={preOrder} />
              </div>
              <div className="form">
                <OrderInvoiceForm order={preOrder} />
              </div>
            </div>
            <div className="form" style={{flex: 1}}>
              <TotalItemAmountForm cart={preOrder} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (!order) {
    // TODO: better ui hint
    return <Wrapper>{/* 無訂單資訊 */}</Wrapper>;
  }

  return (
    <Wrapper>
      <div className="container">
        {showPayFailHint && (
          <div className="block payment-fail-hint">
            <p>{`${
              PAYMENT_STATUS_DISPLAY[order.payment_status]
            }\n（請聯繫發卡銀行確認是否為額度不足或授權失敗。點擊返回購物車按鈕，若庫存仍夠，商品一樣保留在您的購物車內，仍可再次結帳）`}</p>
            <Button
              type={'secondary'}
              size={'small'}
              onClick={() => navigate(URL_PATH.cart)}
              style={{marginTop: 10}}>
              前往購物車
            </Button>
          </div>
        )}
        <div
          className="block order-status"
          style={{justifyContent: 'space-between'}}>
          <div className="col" style={{marginRight: 10}}>
            <h3>訂單狀態：{ORDER_STATUS_DISPLAY[order.status]}</h3>
          </div>
          {showLogisticSearchLink && (
            <div className="col">
              <p>物流單號：{order.logistic_order_id}</p>
              <a
                href="https://www.t-cat.com.tw/inquire/trace.aspx"
                target="_blank">
                物流進度查詢
              </a>
            </div>
          )}
        </div>
        <div className="block payment-result">
          <div className="col title">
            <h3>
              單據編號<span className="order-id">{order.order_id}</span>
            </h3>
            <p>訂單成立時間：{format(order.created, 'YYYY-MM-DD HH:mm')}</p>
          </div>
          {order.payment_status === PAYMENT_STATUS.success ? (
            <div className="item" style={{flex: 2}}>
              <img src={SvgIconPaySuccess} style={{width: 70, height: 70}} />
              <div className="col">
                <h3>付款狀態：付款完成</h3>
                <p>訂單付款完成，為您準備中。</p>
              </div>
            </div>
          ) : (
            <div style={{display: 'flex'}}>
              <img src={SvgIconPayFailure} style={{width: 70, height: 70}} />
              <div className="col">
                <h3>
                  付款狀態：
                  {PAYMENT_STATUS_DISPLAY[order.payment_status] || '--'}
                </h3>
                <p>
                  您的此筆訂單
                  {PAYMENT_STATUS_DISPLAY[order.payment_status] ||
                    PAYMENT_STATUS_DISPLAY[PAYMENT_STATUS.waiting]}
                  ，請至購物車重新訂購。
                </p>
              </div>
            </div>
          )}
        </div>
        <Cart cart={order} />
        <div className="row">
          <div style={{flex: 2}}>
            <div className="form">
              <OrderPaymentMethodForm order={order} />
            </div>
            <div className="form">
              <OrderInfoForm order={order} />
            </div>
            <div className="form">
              <OrderDeliveryForm order={order} />
            </div>
            <div className="form">
              <OrderInvoiceForm order={order} />
            </div>
          </div>
          <div className="form" style={{flex: 1}}>
            <TotalItemAmountForm cart={order} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  font-family: 'Noto Sans TC';

  & > .container {
    max-width: var(--content-max-width);
    padding: 0px var(--content-padding);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 45px;

    & > .block.payment-fail-hint {
      padding: 20px;
      border: 1px solid var(--color-red);
      background-color: var(--color-red-2);
      border-radius: 2px;
      font-size: 14px;
      width: 100%;
      text-align: center;
      white-space: pre-wrap;
      margin-bottom: 40px;
    }

    & > .block.payment-result,
    & > .block.order-status {
      display: flex;
      align-items: center;
      border: 1px solid #cecece;
      border-radius: 5px;
      padding: 5px 16px;
      margin-bottom: 10px;

      & .item {
        display: flex;
      }

      & > .col.title {
        flex: 1;
        border-right: 1px solid #cecece;
      }

      & .col {
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      & .col,
      & .item {
        & h3 {
          color: #595757;
          font-size: 18px;
          font-family: 'Noto Sans TC';
          font-weight: bold;
        }

        & span.order-id {
          color: var(--theme-primary);
          margin-left: 10px;
        }
      }
    }

    & .form {
      margin-bottom: 20px;
    }

    & > .row {
      display: flex;
      margin-top: 40px;

      & > div:last-child {
        margin-left: 20px;
      }

      @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
        flex-direction: column-reverse;
        & > div:last-child {
          margin-left: 0px;
        }
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    & > .container {
      & > .block.payment-result,
      & > .block.order-status {
        flex-direction: column;
        align-items: stretch;

        & > .col.title {
          border-right: none;
          border-bottom: 1px solid #cecece;
          padding-bottom: 5px;
        }
      }
    }
  }
`;
export default OrderPage;
